import React, { useState } from 'react'

import { API } from '../../api/api'
import { Endpoint } from '../../consts/consts'
import { useFetchData } from '../../hooks/use-fetch-data'
import useInterval from '../../hooks/use-interval'
import PaymentDetails from '../payment-details/payment-details'
import {
  BackButton,
  Button,
  CardContainer,
  CardIconsLabel,
  Form,
  FormContainer,
  Input,
  Title,
  IconsContainer,
  InputIcons,
  MainTitle,
  Container,
  CardsTip
} from './payment-form-styles'

import { MasterCard, MIR, PciDcc, VISA } from '../../icons/icons'

import Option from '../option/option'
import PaymentLinks from '../payment-links/payment-links'
import TextSpinner from '../text-spinner/text-spinner'

const Processing = {
  QIWI: `qw`,
  CARD: `card`
}

const options = [
  {
    label: `QIWI`,
    value: 1,
    name: `QWRUB`,
    fee: `5%`,
    paySource: Processing.QIWI,
    code: `qw`
  },
  {
    label: `Банковская карта`,
    value: 2,
    name: `CARD`,
    fee: `4%`,
    paySource: Processing.CARD,
    code: `card`
  }
]

export default function PaymentForm() {
  const urlParameters = window.location.pathname.slice(1)
  const [UID] = useState({ UID: urlParameters })
  // const [UID] = useState({ UID: "0ACDA780-AB74-46EE-8AD5-51FE5991178A" })
  const {
    state: paymentDetails,
    setTrigger,
    isLoading
  } = useFetchData(Endpoint.GET_P2P_REQUEST_FORM_DETAILS, UID)
  const [, setLinkStatus] = useState({
    cause: '',
    success: null
  })

  const [tail, setTail] = useState(``)

  const [paySource, setPaySource] = useState(``)
  const [isLinksLoading, setIsLinksLoading] = useState(false)

  useInterval(() => setTrigger((state) => !state), 5000, isLoading)

  const submitPaymentHandler = async (evt) => {
    evt.preventDefault()
    setIsLinksLoading(true)
    try {
      const { data } = await API({
        method: `POST`,
        url: Endpoint.GENERATE_INVOICE_P2P_BY_REQUEST,
        data: {
          tail: paySource === Processing.CARD ? tail : ``,
          UID: UID.UID,
          paySource
        }
      })
      setTail(``)
      setPaySource(``)
      setTrigger((state) => !state)
      setLinkStatus(data)
    } catch (err) {
      console.log(`err `, err)
    }
    setIsLinksLoading(false)
  }

  console.log(`paymentDetails `, paymentDetails && paymentDetails.selectedPaySourceFilter);

  return (
    <Container>
      <MainTitle>
        {paymentDetails &&
          paymentDetails.displaySettings &&
          paymentDetails.displaySettings.header &&
          paymentDetails.displaySettings.header}
      </MainTitle>
      {!paymentDetails?.paymentFlow ? (
        <Title width='100%'>Пожалуйста выберите способ оплаты</Title>
      ) : (
        <>
          <Title maxWidth='540px'>
            Оплатите заявку, используя указанные ниже платежные ссылки.{' '}
            <br></br>
            {paymentDetails &&
              paymentDetails.selectedPaySourceFilter === Processing.CARD &&
              `Используйте для оплаты карту, указанную вами на предыдущей странице!`}
          </Title>
        </>
      )}
      {paymentDetails && paymentDetails.selectedPaySourceFilter === Processing.CARD && (
        <CardsTip>
          Внимание - на следующей странице укажите полный номер карты. Для
          успешного прохождения платежа не меняйте номер карты
        </CardsTip>
      )}
      <TextSpinner isLinksLoading={isLinksLoading} paymentDetails={paymentDetails} />
      <FormContainer>
        {paySource && <BackButton onClick={() => setPaySource(``)} />}
        <Form onSubmit={submitPaymentHandler}>
          {!paySource &&
            paymentDetails &&
            paymentDetails.allowPaySourcesFilter &&
            !paymentDetails.paymentFlow
            ? options.map((option) => {
              const { label, value, name, paySource, code } = option
              if (
                paymentDetails.allowPaySourcesFilter.includes(`qw`) &&
                code === `qw`
              ) {
                return (
                  <Option
                    key={value}
                    name={name}
                    label={label}
                    paymentDetails={paymentDetails}
                    setPaySource={setPaySource}
                    paySource={paySource}
                  />
                )
              }
              if (
                paymentDetails.allowPaySourcesFilter.includes(`card`) &&
                code === `card`
              ) {
                return (
                  <Option
                    key={value}
                    name={name}
                    label={label}
                    paymentDetails={paymentDetails}
                    setPaySource={setPaySource}
                    paySource={paySource}
                  />
                )
              }
              if (
                paymentDetails.allowPaySourcesFilter.includes(`card`) &&
                paymentDetails.allowPaySourcesFilter.includes(`qw`)
              ) {
                return (
                  <Option
                    key={value}
                    name={name}
                    label={label}
                    paymentDetails={paymentDetails}
                    setPaySource={setPaySource}
                    paySource={paySource}
                  />
                )
              }
            })
            : null}

          {paymentDetails && paymentDetails.paymentFlow ? (
            <PaymentLinks
              paymentDetails={paymentDetails}
              isLoading={isLoading}
            />
          ) : null}
          {paySource === Processing.CARD ? (
            <>
              <CardIconsLabel>Последние 4 цифры карты</CardIconsLabel>
              <CardContainer>
                <Input
                  placeholder='3550'
                  required
                  type='text'
                  minLength='4'
                  value={tail}
                  onChange={({ target }) => setTail(target.value)}
                />
                <InputIcons />
              </CardContainer>
              <Button
                onClick={submitPaymentHandler}
                type='button'
                disabled={tail.length < 4 || isLinksLoading}
              >
                Оплатить {paymentDetails && paymentDetails.amount} ₽
              </Button>
            </>
          ) : null}
          {paySource === Processing.QIWI ? (
            <Button
              onClick={submitPaymentHandler}
              disabled={isLinksLoading}
              type='button'
            >
              Нажмите для оплаты
            </Button>
          ) : null}
        </Form>
        {paymentDetails && paymentDetails ? (
          <PaymentDetails
            paymentDetails={paymentDetails}
            isLoading={isLinksLoading}
          />
        ) : null}
        <IconsContainer>
          <PciDcc />
          <MasterCard />
          <VISA />
          {/* <MIR /> */}
        </IconsContainer>
      </FormContainer>
    </Container>
  )
}
