import React from 'react'
import {
  Icon,
  LabelContainer,
  OptionsContainer,
  Radio
} from '../payment-form/payment-form-styles'
import * as Icons from '../../icons/icons'

export default function Option({
  name,
  paymentDetails,
  setPaySource,
  label,
  paySource
}) {
  return (
    <OptionsContainer icon={name}>
      <Icon>{Icons[name]()}</Icon>
      <LabelContainer>
        <strong>{label}</strong>
      </LabelContainer>
      <Radio
        name='processing'
        type='radio'
        disabled={paymentDetails && paymentDetails.paymentFlow}
        onChange={() => setPaySource(paySource)}
      />
    </OptionsContainer>
  )
}
